
import styled, { css } from "styled-components";
import {
  ZWrapper,
} from '../../ui-components';


export const FaqWrap = styled(ZWrapper)`
  margin-bottom: 1px;
  text-align: left;
  
  ${props => props.isOpen && css`
    ${FaqAnswer}{
      display: block;
    }
  `};
`

export const FaqQuestion = styled.h3`
  font-family: "Quantico", sans-serif;
  font-size: 18px;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.primary.main};
  cursor: pointer; 

  &:hover{
    svg{
      fill: ${({ theme }) => theme.thirdiary.main};
    }
  }

  svg {
    position: relative;
    top: 2px;
    margin-right: 4px;
    fill: ${({ theme }) => theme.text.disabled};
  }
`

export const FaqAnswer = styled.div`
  position: relative;
  display: none;
  padding: 16px;
  

  p {
    margin: 0;
    font-size: 16px;
    line-height: 21px;
  }
`

export const FaqTitle = styled.h2`
  font-family: "Quantico", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 21px;
  margin-left: 14px;
  color: ${({ theme }) => theme.text.secondary};
  text-align: left;
`;


export const FaqCollection = styled.div`
  box-sizing: content-box;
`