import React, { useState } from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
} from '../ui-components';
import Faq from "../components/faq-acordeon/faq-acordeon";
import {
  FaqTitle,
  FaqCollection,
} from "../components/faq-acordeon/faq-acordeon.styled";
import PreOrder from "../components/pre-order/pre-order";



const FaqPage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html;
  const faqs = data.faq.edges.map((element) => {
    const data = element.node.childMarkdownRemark;
    return {
      question: data.frontmatter.question,
      group: data.frontmatter.group,
      answer: data.html,
    }
  })
  const faqGroup = data.faq_group.edges.map(element => element.node.childMarkdownRemark.frontmatter)
  const faqCollection = {}
  faqGroup.forEach(element => {
    faqCollection[element.title] = []
  });

  faqs.forEach(element => {
    faqCollection[element.group].push(element)
  });

  const openFlag = false;

  return (
    <>
      <SEO
        canonical="/faq/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <br />
          {Object.entries(faqCollection).map(([title, collection], index) =>
            <FaqCollection key={index}>
              <FaqTitle>{title}</FaqTitle>
              {collection.map((data, key) => <Faq key={key} question={data.question} answer={data.answer} isOpen={key === 0 && index === 0} />)}
            </FaqCollection>
          )}

          <PreOrder />

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}
export default FaqPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/faq.md/"}) {
    frontmatter {
      title
    }
    html
  }
  faq_group: allFile(filter: {absolutePath: {regex: "/content/faq_group//"}}, sort: {fields: childrenMarkdownRemark___frontmatter___order, order: ASC}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            title
            order
          }
        }
      }
    }
  }
  faq: allFile(filter: {absolutePath: {regex: "/content/faq//"}}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            question
            group
          }
          html
        }
      }
    }
  }
}  
`;