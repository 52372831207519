import React, { useState } from "react";
import {
  Icon,
} from '../../ui-components';
import {
  FaqQuestion,
  FaqAnswer,
  FaqWrap,
} from './faq-acordeon.styled';





const Faq = ({ question, answer, isOpen = false }) => {
  const [open, setOpen] = useState(isOpen);
  return (
    <FaqWrap isOpen={open}>
      <FaqQuestion onClick={() => setOpen(!open)}>
        <Icon
          name={open ? "arrow-ios-downward-outline" : "arrow-ios-forward-outline"}
          width={18}
          height={18}
          noFill
        />
        {question}
      </FaqQuestion>
      <FaqAnswer dangerouslySetInnerHTML={{ __html: answer }} />
    </FaqWrap>
  )
}

export default Faq;
